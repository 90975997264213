<template>
  <div>
    <b-button
      :variant="variant"
      icon-pack="feather"
      icon="icon-link-2"
      class="mt-1 w-100 shadow-sm"
      @click="shareJobConfirmation"
    >
      <feather-icon
        v-if="$isNotEmpty(icon)"
        :icon="icon"
        class="mr-1"
      />
      {{ shareJobText }}
    </b-button>

    <b-modal
      id="modal-lg"
      ref="first-share-popup"
      v-model="shareJobModal"
      centered
      ok-title="Share & Apply Later"
      ok-variant="outline-secondary"
      cancel-title="Continue Search"
      cancel-variant="success"
      :title=" `Share Job${$length(jobList) > 1 ? 's' : ''}`"
      @ok="proceedFunc"
      @cancel="cancelFunc"
    >
      <div class="">
        <div
          v-if="getLSJobs()"
        >
          <h6>Roles you have selected to share and apply later:</h6>
          <ul
            class="overflow-auto"
            style="max-height: 5cm;"
          >
            <li
              v-for="(item, index) in getLSJobs()"
              :key="index"
              class="ml-2"
            >
              <strong>{{ item }}</strong>
            </li>
          </ul>
        </div>
        <p>You can share more than 1 job at once. Would you like to continue to search for more jobs?</p>
      </div>

    </b-modal>

  </div>
</template>

<script>
export default {
  props: {
    jobDetails: {
      type: Object,
      default: () => {},
    },
    variant: {
      type: String,
      default: 'primary',
    },
    icon: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      modals: {
        confirmation: {
          state: false,
        },
      },
      shareJobText: 'Share & Apply Later',
      jobList: '',
      shareJobModal: false,
    }
  },

  watch: {

  },
  mounted() {
  },
  methods: {

    proceedFunc() {
      this.$emit('next-step', true)
    },
    cancelFunc() {
      const currentPage = this.$route.name
      const startLocation = this.$router.history._startLocation
      if (currentPage === 'kiosk-page-event') {
        this.$router.push({ path: startLocation })
        if (startLocation.includes('/kiosk/job/')) {
          this.$router.push({ path: '/kiosk' })
        }
      }
    },
    getLSJobs() {
      const shareJobList = JSON.parse(localStorage.getItem('share_job_list')) || null
      let positionText = null
      if (shareJobList) {
        positionText = Object.values(shareJobList).map(item => item.position)
      }
      return positionText
    },

    shareJobConfirmation() {
      const shareJobList = JSON.parse(localStorage.getItem('share_job_list')) || {}
      shareJobList[this.jobDetails.id] = {
        id: this.jobDetails.id,
        position: this.jobDetails.position,
        company: this.jobDetails.company ? this.jobDetails.company : this.jobDetails.company_name,
      }

      localStorage.setItem('share_job_list', JSON.stringify(shareJobList))

      this.jobList = shareJobList
      this.shareJobModal = true
      const _this = this
      // this.$simpleConfirmModal(
      //   this,
      //   searchText,
      //   {
      //     okTitle: `Share Job${this.$length(shareJobList) > 1 ? 's' : ''}`,
      //     okVariant: 'outline-secondary',
      //     cancelTitle: 'Continue Search',
      //     cancelVariant: 'success',
      //   },
      // )
      //   .then(value => {
      //     this.$emit('next-step', true)
      //   })
      //   .catch(err => {
      //     const currentPage = _this.$route.name
      //     const startLocation = _this.$router.history._startLocation
      //     if (currentPage === 'kiosk-page-event') {
      //       this.$router.push({ path: startLocation })
      //       if (startLocation.includes('/kiosk/job/')) {
      //         this.$router.push({ path: '/kiosk' })
      //       }
      //     }
      //     // do nothing, confitnue browsing
      //   })
    },
  },
}
</script>

<style>

</style>
